<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <el-tag
      :key="index"
      v-for="(tag,index) in dynamicTags"
      closable
      :disable-transitions="false"
      @close="handleClose(tag,index)"
    >
      {{tag.name}}
    </el-tag>
    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      size="small"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button
      v-else-if="$can('admin/label/feedback-create')"
      class="button-new-tag"
      size="small"
      @click="showInput"
    >+ 新增</el-button>

  </div>
</template>

<script>
import LabelService from '@admin/services/LabelService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'LabelFeedback',
  data () {
    return {
      pageTitle: '创建反馈标签',
      dynamicTags: [],
      inputVisible: false,
      inputValue: ''
    }
  },
  async created () {
    const { data } = await flatry(LabelService.feedback())
    if (data) {
      this.dynamicTags = data.data
    }
  },
  methods: {
    // 删除
    async handleClose (tag, index) {
      const { data } = await flatry(LabelService.feedbackDel(tag))
      if (data) {
        this.dynamicTags.splice(index, 1)
      }
    },

    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    // 新增
    async handleInputConfirm () {
      let inputValue = this.inputValue
      if (inputValue) {
        const { data } = await flatry(
          LabelService.feedbackCreate({ name: inputValue })
        )
        this.dynamicTags.push(data.data)
      }
      this.inputVisible = false
      this.inputValue = ''
    }
  }
}
</script>
<style lang='scss' scoped>
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>
